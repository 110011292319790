import { Footer, SEO } from 'components';
import { motion } from 'framer-motion';
import { FormattedMessage, navigate, useIntl } from 'gatsby-plugin-intl';
import PageTransition from 'gatsby-v2-plugin-page-transitions';
import { MeasurementThunkActions } from 'modules/measurements/redux/thunks';
import { AppState } from 'modules/redux-store';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cta, measure, spacing, titles, utils } from 'style';

const RibMeasure: React.FC = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const ribMeasure = useSelector(
    (state: AppState) => state.measurements.ribMeasure,
  );
  const [error, setError] = useState<string>();

  const buttonAnimation = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: 40 },
  };

  return (
    <PageTransition>
      <SEO
        title={intl.formatMessage({ id: 'ribMeasurePageSeo' })}
        url="https://find-your-fit.eu/en/rib-measure"
        urlEN="https://find-your-fit.eu/en/rib-measure"
        urlDE="https://find-your-fit.eu/de/rib-measure"
        urlIT="https://find-your-fit.eu/it/rib-measure"
        urlNL="https://find-your-fit.eu/nl/rib-measure"
        urlFR="https://find-your-fit.eu/fr/rib-measure"
      />
      <div css={utils.wrapper}>
        <section css={[measure.root]}>
          <motion.div
            css={measure.visual}
            animate={{ opacity: [0, 1] }}
            transition={{ duration: 1.5, ease: [0.22, 1, 0.36, 1] }}
          >
            <img
              src={require('assets/images/measure/measure-step1b.gif')}
              alt=""
            />
          </motion.div>

          <motion.h1
            css={[measure.title, titles.primary]}
            animate={{ y: [40, 0], opacity: [0, 1] }}
            transition={{ delay: 0.5, duration: 1, ease: [0.22, 1, 0.36, 1] }}
          >
            <FormattedMessage id="stepOne" />
            <br />
            <span css={utils.fontFamilySansAlt}>
              <FormattedMessage id="ribMeasure" />.
            </span>
          </motion.h1>

          <motion.div
            css={measure.content}
            animate={{ y: [40, 0], opacity: [0, 1] }}
            transition={{ delay: 0.5, duration: 1, ease: [0.22, 1, 0.36, 1] }}
          >
            <p css={[utils.typeLarge, utils.fontFamilySansAlt]}>
              <FormattedMessage id="ribMeasureDescription" />
            </p>
          </motion.div>

          <motion.div
            css={measure.field}
            animate={{ y: [40, 0], opacity: [0, 1] }}
            transition={{ delay: 1, duration: 1, ease: [0.22, 1, 0.36, 1] }}
          >
            <input
              onChange={handleInputChange}
              id="ribMeasure"
              name="ribMeasure"
              type="number"
              css={measure.input}
              min="63"
              max="92"
              placeholder="00"
            />
            <p
              css={[utils.typeLarge, utils.fontFamilySansAlt, utils.typeUpper]}
            >
              CM
            </p>
          </motion.div>
          <div css={measure.validation}>
            {error && (
              <p
                css={[
                  utils.messageWarning,
                  spacing.top.medium,
                  utils.typeCenter,
                ]}
              >
                <FormattedMessage id="measurementMissing" />
              </p>
            )}
          </div>

          <motion.button
            animate={ribMeasure ? 'visible' : 'hidden'}
            variants={buttonAnimation}
            onClick={handleClickNext}
            css={[cta.button, cta.buttonHiddenByDefault]}
          >
            <FormattedMessage id="ctaNext" />
          </motion.button>
        </section>
      </div>
      <Footer />
    </PageTransition>
  );

  function handleClickNext() {
    if (!ribMeasure) {
      setError('Please enter data');
      return;
    }

    if (!ribMeasure) {
      return setError('Please your band measure');
    }

    if (ribMeasure > 92 || ribMeasure < 63) {
      return setError('The value must be between 63-92cm');
    }

    navigate('/cup-size');
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.currentTarget;

    if (error) {
      setError(undefined);
    }

    dispatch(MeasurementThunkActions.updateRibMeasure(parseInt(value)));
  }
};

export default RibMeasure;
